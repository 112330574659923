import {
  CREATE_OFFER_SUCCESS,
  DELETE_OFFER_SUCCESS,
  GET_ALL_OFFER_SUCCESS,
  OFFER_FAILURE,
  OFFER_REQUEST,
  SEARCHED_OFFER_SUCCESS,
  UPDATE_OFFER_SUCCESS,
  GET_ALL_REQUESTED_OFFER_SUCCESS,
  UPDATE_REQUESTED_OFFER_SUCCESS,
  UPDATE_REQUESTED_OFFER_LINK_SUCCESS,
  UPDATE_OFFER_ISREQUESTED_SUCCESS,
  SEARCHED_REQUESTED_OFFERS,
  SEARCHED_OFFER_BY_CATEGORY_SUCCESS,
  GET_ALL_MY_APPROVED_OFFER_SUCCESS,
  CREATE_PROMOTE_SUCCESS,
  REMOVE_PROMOTED_OFFER_SUCCESS,
  GET_ALL_PROMOTED_OFFER_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

const requestedOffersInitialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export function offerReducer(offerState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OFFER_REQUEST:
      return {
        ...offerState,
        loading: true,
        error: false,
      };
    case OFFER_FAILURE:
      return {
        ...offerState,
        loading: false,
        error: true,
        message: payload?.message,
      };

    case CREATE_OFFER_SUCCESS:
      return {
        ...offerState,
        loading: false,
        error: false,
        // data: [payload, ...offerState.data],
        data: [payload, ...offerState.data],
      };

    case GET_ALL_OFFER_SUCCESS:
      return {
        ...offerState,
        data: payload,
        loading: false,
        error: false,
      };
    case GET_ALL_PROMOTED_OFFER_SUCCESS:
      return {
        ...offerState,
        data: payload,
        loading: false,
        error: false,
      };

    case GET_ALL_MY_APPROVED_OFFER_SUCCESS:
      return {
        ...offerState,
        data: payload,
        loading: false,
        error: false,
      };

    case UPDATE_OFFER_SUCCESS: {
      return {
        ...offerState,
        data: offerState.data.map((offer) =>
          offer._id === payload._id ? payload : offer
        ),
        error: false,
        loading: false,
      };
    }

    case CREATE_PROMOTE_SUCCESS :{
      return {
        ...offerState,
        data: offerState.data.map((offer) =>
          offer._id === payload._id ? payload : offer
        ),
        error: false,
        loading: false,

      }
    }

    

    case UPDATE_OFFER_ISREQUESTED_SUCCESS:
      return {
        ...offerState,
        data: offerState.data.map((offer) => {
          if (offer._id === payload.offer) {
            // eslint-disable-next-line
            return { ...offer, ["hasRequested"]: payload.hasRequested };
          }
          return offer;
        }),
        error: false,
        loading: false,
      };

    case DELETE_OFFER_SUCCESS:
      return {
        ...offerState,
        data: offerState.data.filter((offer) => offer._id !== payload._id),
        error: false,
        loading: false,
      };
    case REMOVE_PROMOTED_OFFER_SUCCESS:
      return {
        ...offerState,
        data: offerState.data.map((offer) =>
        offer._id === payload._id ? payload : offer
      ),
        error: false,
        loading: false,
      };

    case SEARCHED_OFFER_SUCCESS:
      return {
        ...offerState,
        data: payload,
        error: false,
        loading: false,
      };

      case SEARCHED_OFFER_BY_CATEGORY_SUCCESS: {
        return {
          ...offerState,
          data: payload,
          error: false,
          loading: false,
        }; 
      }

    default:
      return offerState;
  }
}

export const getAllRequestedOfferReducer = (
  requestedOffersState = requestedOffersInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case OFFER_REQUEST:
      return {
        ...requestedOffersState,
        loading: true,
        error: false,
        message: "",
      };
    case GET_ALL_REQUESTED_OFFER_SUCCESS:
      return {
        ...requestedOffersState,
        data: payload,
        error: false,
        loading: false,
      };

      case SEARCHED_REQUESTED_OFFERS:
        return {
          ...requestedOffersState,
          data: payload,
          error: false,
          loading: false,
        }

    case UPDATE_REQUESTED_OFFER_SUCCESS:
      return {
        ...requestedOffersState,
        data: requestedOffersState.data.map((item) =>
          item._id === payload?._id ? payload : item
        ),
        error: false,
        loading: false,
      };
      case UPDATE_REQUESTED_OFFER_LINK_SUCCESS:
        //Offer key updated in requested offers
      return {
        ...requestedOffersState,
        data: requestedOffersState.data.map((item) =>
        item?._id === payload?._id
        // eslint-disable-next-line
            ? { ...item, ["offerLink"]:payload?.offerLink }
            : item
        ),
        error: false,
        loading: false,
      };
    default:
      return requestedOffersState;
  }
};