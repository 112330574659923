import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Table,
  notification,
} from "antd";
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  approveDisapproveRequestedOffer,
  getAllRequestedOffers,
  updateRequestedUrlLink,
} from "../../../../redux/actions/offer";
const RequestedOffers = () => {
  const allRequestedOffers = useSelector((store) => store?.allRequestedOffer);
  const dispatch = useDispatch();
  const [requestedOfferForm] = Form.useForm();
  const [filteredInfo] = useState({});
  const [sortedInfo] = useState({});
  const [id, setId] = useState("");
  const [mainId,setMainId] = useState("")
  const [userId,setUserId]= useState('');
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [itemCount, setItemCount] = useState(allRequestedOffers?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const columns = [
    {
      title: "Affiliate Name",
      dataIndex: "affiliateName",
    },
    {
      title: "Affiliate Email",
      dataIndex: "affiliateEmail",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Offer",
      dataIndex: "offer",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Offer Link",
      dataIndex: "landingPageUrl",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === "address" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];
  const handleMenuClick = async (id, name, email, offer, landingPageUrl, e) => {
    const { key } = e;
    let checkBool;
    if (key === "true") {
      checkBool = true;
    } else {
      checkBool = false;
    }
    const approveDisapproveResult = await dispatch(
      approveDisapproveRequestedOffer(id, checkBool)
    );
    if (approveDisapproveResult?.data) {
      if (approveDisapproveResult?.data?.isApproved) {
        setOfferModalOpen(true);
        setId(approveDisapproveResult?.data?.offer?._id);
        setUserId(approveDisapproveResult?.data?.offerRequestedBy?._id);
        setMainId(id)
        requestedOfferForm.setFieldsValue({
          key: id,
          landingPageUrl: landingPageUrl,
        });
      }
      api.success({
        message: approveDisapproveResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: approveDisapproveResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };
  const items = [
    {
      label: "Approve",
      key: true,

      icon: <CheckOutlined />,
    },
    {
      label: "Reject",
      key: false,

      icon: <CloseOutlined />,
    },
    // {
    //   label: "Edit",
    //   key: "edit",

    //   icon: <EditOutlined />,
    // },
    // {
    //   label: "Delete",
    //   key: "delete",

    //   icon: <DeleteOutlined />,
    // },
  ];
  const data = allRequestedOffers?.data?.map((item) => {
    return {
      affiliateName: item?.offerRequestedBy?.name,
      affiliateEmail: item?.offerRequestedBy?.email,
      offer: item?.offer?.name,
      landingPageUrl: item?.offerLink,
      // landingPageUrl: item?.offer?.landingPageUrl,
      status:
        item.isApproved === true && item?.reviewedBy ? (
          <p className="font-Mulish font-semibold text-base text-[#047B25]">
            Approved
          </p>
        ) : item.isApproved === false && item?.reviewedBy ? (
          <p className="font-Mulish font-semibold text-base text-[#FC0303]">
            Rejected
          </p>
        ) : (
          <p className="font-Mulish font-semibold text-base text-[#FFA500]">
            Pending
          </p>
        ),
      action: (
        <Dropdown
          menu={{
            items,
            onClick: (e) =>
              handleMenuClick(
                item._id,
                item?.offerRequestedBy?.name,
                item?.offerRequestedBy?.email,
                item?.offer?.name,
                item?.offerLink,
                e
              ),
          }}
          className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
        >
          <Button>
            <Space>
              Action
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    };
  });
  const NoData = () => (
    <div className="font-Mulish font-semibold text-lg text-black">
      No Data Found
    </div>
  );

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onAddFinish = async (values) => {
    setOfferModalOpen(false);
    const addLinkResult = await dispatch(updateRequestedUrlLink(id,userId, values,mainId));
    if (addLinkResult?.data) {
      api.success({
        message: addLinkResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: addLinkResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };
  const onOfferFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };
  useEffect(() => {
    (async () => {
      const response = await dispatch(
        getAllRequestedOffers(pageSize, currPage)
      );
      setItemCount(response?.itemCount);
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage]);
  return (
    <div className="bg-white">
      <div>
        {contextHolder}
        <Table
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          locale={{ emptyText: <NoData /> }}
          loading={allRequestedOffers?.loading}
          pagination={paginationConfig}
        />
        <Modal
          title=""
          centered
          form={requestedOfferForm}
          open={offerModalOpen}
          className="p-0 modal_parent_wrapper"
          onOk={() => setOfferModalOpen(false)}
          onCancel={() => setOfferModalOpen(false)}
          footer={[
            <Button
              form="requestedOfferForm"
              key="submit"
              htmlType="submit"
              type="primary"
              className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
            >
              ADD
            </Button>,
          ]}
        >
          <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
            Add Offer Link
          </h2>
          <Form
            name="basic"
            layout="vertical"
            id="requestedOfferForm"
            form={requestedOfferForm}
            style={{ maxWidth: 600 }}
            className="p-4"
            initialValues={{ remember: true }}
            onFinish={onAddFinish}
            onFinishFailed={onOfferFinishFailed}
            autoComplete="off"
          >
            <div className="w-full pt-5">
              <Form.Item
                label="Offer Link"
                name="landingPageUrl"
                className="font-semibold mb-3"
              >
                <Input
                  className=" py-2 rounded-2xl w-full"
                  placeholder="need to work on this add button"
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default RequestedOffers;
